/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

// New Styles

td, tr{
  text-align: center;
  vertical-align: middle;
}

.table th {
  font-weight: 600;
  font-size: 14px;
}

.table td {
  font-weight: 600;
  font-size: 13px;
}

.submitBtn{
  background-color: #033459!important;
  color: #fff!important;
}

.remove_Btn{
  background-color: #780000!important;
  color: #fff!important;
}

.alignContent-spaceBetween{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-group{
  margin-bottom: 10px;
  label{
    font-weight: 600;
    color: #01345b;
  }
}

.fadeAlert{
  transition: .3s;
}

.delivered{
  color: #008b59!important;
  background-color: rgba(52, 195, 143, 0.18)!important;
  padding: 5px 10px!important;
  border-radius: 20px!important;
}

.rejected{
  color: #ef3535!important;
  background-color: rgba(244, 106, 106, 0.18)!important;
  padding: 5px 10px!important;
  border-radius: 20px!important;
}

.pending{
  color: #ff7300!important;
  background-color: rgba(255, 202, 116, 0.18)!important;
  padding: 5px 10px!important;
  border-radius: 20px!important;
}

.canceled{
  color: #252525!important;
  background-color: rgba(112, 112, 112, 0.18)!important;
  padding: 5px 10px!important;
  border-radius: 20px!important;
}

.onHisWay{
  color: #000153!important;
  background-color: rgba(116, 132, 255, 0.18)!important;
  padding: 5px 10px!important;
  border-radius: 20px!important;
}

.assignBtn{
  color: #000153!important;
  background-color: rgba(116, 132, 255, 0.18)!important;
  margin: 3px!important;
  font-weight: 600!important;
}

.approveBtn{
  color: #008b59!important;
  background-color: rgba(52, 195, 143, 0.18)!important;
  margin: 3px!important;
  font-weight: 600!important;
}

.deleteBtn{
  background-color: #ffd6d6!important;
  color: rgb(182, 0, 0)!important;
  margin: 3px!important;
  font-weight: 600!important;
}

.cancelBtn{
  background-color: #e9e9e9!important;
  color: rgb(39, 39, 39)!important;
  margin: 3px!important;
  font-weight: 600!important;
}

.form-control{
  appearance: auto!important;
  cursor: pointer!important;
}

.btnPadding{
  padding-top: 28px;
}

/* width */
.table-responsive::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background: #222736; 
  border-radius: 10px;
}

/* Handle on hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #222736; 
}

.dataTables_wrapper{
  margin-bottom: 20px;
}

table.dataTable thead th, table.dataTable thead td{
  text-align: center!important;
}
.detailsModal {
  .modal-lg{
    max-width: 80%!important;
    margin: auto;
  }
}

@media screen and (max-width: 640px){
  .dataTables_wrapper .dataTables_filter label {
      width: 100%;
      text-align: left;
  }
  .dataTables_wrapper .dataTables_length{
    float: left!important;
  }
}

table.dataTable tbody th, table.dataTable tbody td {
  padding: 5px 10px!important;
}

.card-body{
  box-shadow: 0px 0px 5px 0px #c7c7c7;
}

@media (max-width: 630px) {
  .fc-toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 10px;
  }
}

.fc-daygrid-dot-event{
  flex-direction: column;
  align-items: flex-start!important;

  .fc-daygrid-event-dot{
    display: none;
  }
}

.fc-license-message{display: none;}


.fc .fc-timeline-slots {
  border-top: 1px solid #ddd!important;
}

.fc-direction-ltr .fc-timeline-event.fc-event-end, .fc-direction-ltr .fc-timeline-more-link {
  margin: 3px;
}

.fc-h-event .fc-event-main-frame {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
}

.fc-timeline-event .fc-event-time, .fc-timeline-event .fc-event-title {
  text-align: left;
}
.fc-h-event .fc-event-title-container {
  text-align: left;
}

.fc-datagrid-cell-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 15px;
}

.fc .fc-datagrid-header .fc-datagrid-cell-frame {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventColor{
  background-color: #033459!important;
  color: #fff;
  transition: .3s;
}

.eventColor:hover{
  background-color: #054677!important;
  color: #fff;
}

.fc-timeline-event.activeDay{
  border: 2px solid #005707!important;
  background: #329b4d!important;
}

.activeDay{
  border: 2px solid #005707!important;
  background: #329b4d!important;
}